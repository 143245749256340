import React from 'react';
import { styled } from '@material-ui/core/styles';

import Controls from './components/Controls/Controls';
import LocalVideoPreview from './components/LocalVideoPreview/LocalVideoPreview';
import MenuBar from './components/MenuBar/MenuBar';
import ReconnectingNotification from './components/ReconnectingNotification/ReconnectingNotification';
import Room from './components/Room/Room';

import useRoomState from './hooks/useRoomState/useRoomState';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  '@media only screen and (max-width: 959px)': {
    height: '90vh',
  },
  '@media only screen and (min-width: 960px)': {
    height: '100vh',
  },
});

const Main = styled('main')({
  height: '100%',
  position: 'relative',
});

export default function App() {
  const roomState = useRoomState();
  let criOS = false;
  if (/CriOS/i.test(navigator.userAgent) && /iphone|ipod|ipad/i.test(navigator.userAgent)) {
    criOS = true;
  } else {
    criOS = false;
  }
  return (
    <Container>
      {criOS && <h2>Chrome on iOS not supported. Please open on Safari.</h2>}
      {!criOS && (
        <React.Fragment>
          <MenuBar />
          <Main>
            {roomState === 'disconnected' ? <LocalVideoPreview /> : <Room />}
            <Controls />
          </Main>
          <ReconnectingNotification />
        </React.Fragment>
      )}
    </Container>
  );
}
